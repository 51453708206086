import { lazy, Suspense } from "react";
import { Loader } from "../Loader";

const ModelingAndDesign = lazy(() => import("../BlogPage/3DModelingAndDesign"));
const ContentMarketing = lazy(() => import("../BlogPage/ContentMarketing"));
const EmailMarketing = lazy(() => import("../BlogPage/EmailMarketing"));
const EmailTemplateDesion = lazy(() =>
  import("../BlogPage/EmailTemplateDesion")
);
const ExhibitionAndSigngeDesign = lazy(() =>
  import("../BlogPage/ExhibitionAndSignageDesign")
);
const GraphicAndPrintDesion = lazy(() =>
  import("../BlogPage/GraphicAndPrintDesion")
);
const PayPerClick = lazy(() => import("../BlogPage/PayPerClick"));
const PromoVideoMarketing = lazy(() =>
  import("../BlogPage/PromoVideoProductionAndDesion")
);
const Seo = lazy(() => import("../BlogPage/SearchEngineOptimization"));
const SocialEngineMarketing = lazy(() =>
  import("../BlogPage/SocialEngineMarketing")
);
const SocialMediaDesign = lazy(() => import("../BlogPage/SocialMediaDesign"));
const SocialMediaMarketing = lazy(() =>
  import("../BlogPage/SocialMediaMarketing")
);
const ContentExplore = lazy(() => import("../BlogPage2/ContentExplore"));
const KeywordsExp = lazy(() => import("../BlogPage2/KeywordsExplorer"));
const RankTracker = lazy(() => import("../BlogPage2/RankTracker"));
const SiteAudit = lazy(() => import("../BlogPage2/SiteAudit"));
const SiteExp = lazy(() => import("../BlogPage2/SiteExplore"));
const AboutUs = lazy(() => import("../Components/AboutUs"));
const Blogs = lazy(() => import("../Components/Blog"));
const BlogDetails = lazy(() => import("../Components/BlogDetails"));
const ContactUs = lazy(() => import("../Components/ContactUs"));
const HomePage = lazy(() => import("../Components/HomePage"));
const SideButton = lazy(() => import("../Components/SideButton"));
const About = lazy(() => import("../Pages/About"));
const FAQ = lazy(() => import("../Pages/FAQ"));
const OurPatner = lazy(() => import("../Pages/OurPartners"));
const OurServices = lazy(() => import("../Pages/OurServices"));
const Testimonials = lazy(() => import("../Pages/Testimonials"));
const WhyUs = lazy(() => import("../Pages/WhyUs"));

export const routes = [
  {
    id: 1,
    path: "/",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <HomePage />
      </Suspense>
    ),
  },
  {
    id: 2,
    path: "/contact_us",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <ContactUs />
      </Suspense>
    ),
  },
  {
    id: 3,
    path: "/blogs",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <Blogs />
      </Suspense>
    ),
  },
  {
    id: 4,
    path: "/about_us",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <AboutUs />
      </Suspense>
    ),
  },
  {
    id: 5,
    path: "/about",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <About />
      </Suspense>
    ),
  },
  {
    id: 6,
    path: "/why_us",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <WhyUs />
      </Suspense>
    ),
  },
  {
    id: 7,
    path: "/our_services",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <OurServices />
      </Suspense>
    ),
  },
  {
    id: 8,
    path: "/our_patner",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <OurPatner />
      </Suspense>
    ),
  },
  {
    id: 9,
    path: "/blogs/:slug",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <BlogDetails />
      </Suspense>
    ),
  },
  {
    id: 10,
    path: "/search_engine_optimization",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <Seo />
      </Suspense>
    ),
  },
  {
    id: 11,
    path: "/testimonials",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <Testimonials />
      </Suspense>
    ),
  },
  {
    id: 13,
    path: "/promo_video_marketing",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <PromoVideoMarketing />
      </Suspense>
    ),
  },
  {
    id: 14,
    path: "/3D_modeling_and_design",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <ModelingAndDesign />
      </Suspense>
    ),
  },
  {
    id: 15,
    path: "/email_template_desion",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <EmailTemplateDesion />
      </Suspense>
    ),
  },
  {
    id: 16,
    path: "/social_media_design",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <SocialMediaDesign />
      </Suspense>
    ),
  },
  {
    id: 17,
    path: "/exhibition_and_signge_design",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <ExhibitionAndSigngeDesign />
      </Suspense>
    ),
  },
  {
    id: 18,
    path: "/graphic_and_print_desion",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <GraphicAndPrintDesion />
      </Suspense>
    ),
  },
  {
    id: 19,
    path: "/email_marketing",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <EmailMarketing />
      </Suspense>
    ),
  },
  {
    id: 20,
    path: "/social_engine_marketing",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <SocialEngineMarketing />
      </Suspense>
    ),
  },
  {
    id: 21,
    path: "/social_media_marketing",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <SocialMediaMarketing />
      </Suspense>
    ),
  },
  {
    id: 22,
    path: "/content_marketing",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <ContentMarketing />
      </Suspense>
    ),
  },
  {
    id: 23,
    path: "/site_exp",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <SiteExp />
      </Suspense>
    ),
  },
  {
    id: 24,
    path: "/keywords_exp",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <KeywordsExp />
      </Suspense>
    ),
  },
  {
    id: 25,
    path: "/site_audit",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <SiteAudit />
      </Suspense>
    ),
  },
  {
    id: 26,
    path: "/rank_tracker",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <RankTracker />
      </Suspense>
    ),
  },
  {
    id: 27,
    path: "/content_explore",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <ContentExplore />
      </Suspense>
    ),
  },
  {
    id: 28,
    path: "/pay_per_click",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <PayPerClick />
      </Suspense>
    ),
  },
  {
    id: 28,
    path: "/side_button",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <SideButton />
      </Suspense>
    ),
  },
  {
    id: 29,
    path: "/faq",
    element: (
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {" "}
        <FAQ />
      </Suspense>
    ),
  },
];
